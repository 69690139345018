<template>
  <div
    class="h-screen flex w-full vx-row no-gutter items-center justify-center page"
    style="color: #626262"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="card-bg page-card">
          <div class="vx-row no-gutter justify-center items-center">
            <img
              src="@/application/assets/images/graphics/dna-1.png"
              style="height: 150px;"
            />
          </div>

          <div class="header-text">
            <h2>{{ $l.get("signUp.step-1.header") }}</h2>
            <p>{{ $l.get("signUp.step-1.body") }}</p>
          </div>
          <!-- <div class="animated shake delay-1s"> -->
          <vs-upload
            :action="uploadAddress"
            fileName="raw"
            :limit="1"
            :show-upload-button="true"
            :automatic="true"
            accept=".txt, .csv, .zip"
            :text="$l.get('welcome.upload-area', 'upper')"
            @on-success="onUploadSuccess"
            @on-fail="onUploadFail"
          />
          <div class="header-text">
            <p v-html="$l.get('signUp.terms-and-conditions-accept')"></p>
          </div>
          <!-- </div> -->
          <div style="margin-top: 30px"></div>
          <vs-divider>{{ $l.get("dictionary.or", "upper") }}</vs-divider>
          <vs-button color="primary" type="flat" @click="onLoginButtonClick">{{
            $l.get("welcome.login-button")
          }}</vs-button>
          <vs-button color="primary" type="flat">{{
            $l.get("dictionary.terms-and-conditions")
          }}</vs-button>
          <vs-button color="primary" type="flat">{{
            $l.get("dictionary.privacy-policy")
          }}</vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import InitSignUpUploadScreenInteractor from "@/business/sign-up-upload/init-sign-up-upload-screen";
import UploadRawDataFailInteractor from "@/business/sign-up-upload/upload-raw-data-fail";
import UploadRawDataSuccessInteractor from "@/business/sign-up-upload/upload-raw-data-success";
import SelectTermsAndConditionsInteractor from "@/business/sign-up-upload/select-terms-and-conditions";
import SelectPrivacyPolicyInteractor from "@/business/sign-up-upload/select-privacy-policy";
import SelectLoginInteractor from "@/business/sign-up-upload/select-login";
import SignUpUploadScreenController from "@/adapters/controllers/screen-sign-up-upload";

export default {
  screen_name: "sign-up-upload",
  data() {
    return {
      controller: null,
      rawDataGateway: null,
      interactors: {
        initSignUpUploadScreen: null,
        uploadRawDataFail: null,
        uploadRawDataSuccess: null,
        acceptTermsAndConditions: null,
        selectPrivacyPolicy: null,
        selectTermsAndConditions: null,
        selectLogin: null
      },
      data: {
        step: 1
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(SignUpUploadScreenController);
    this.rawDataGateway = this.$injector.get("RawDataGateway");

    //{ INTERACTORS
    this.interactors.selectLogin = this.$injector.get(SelectLoginInteractor);
    this.interactors.selectTermsAndConditions = this.$injector.get(
      SelectTermsAndConditionsInteractor
    );
    this.interactors.selectPrivacyPolicy = this.$injector.get(
      SelectPrivacyPolicyInteractor
    );
    this.interactors.uploadRawDataSuccess = this.$injector.get(
      UploadRawDataSuccessInteractor
    );
    this.interactors.uploadRawDataFail = this.$injector.get(
      UploadRawDataFailInteractor
    );
    this.interactors.initSignUpUploadScreen = this.$injector.get(
      InitSignUpUploadScreenInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
  },
  mounted() {
    this.interactors.initSignUpUploadScreen.handle(this.$context);
  },
  computed: {
    uploadAddress() {
      return `${this.rawDataGateway.URL}/`;
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    onLoginButtonClick() {
      this.interactors.selectLogin.handle();
    },
    onUploadSuccess(data) {
      let rawData = JSON.parse(data.target.responseText);
      this.interactors.uploadRawDataSuccess.handle(rawData);
    },
    onUploadFail(data) {
      // print(data);
      this.interactors.uploadRawDataFail.handle();
    }
  }
};
</script>

<style lang="scss" scoped>
.card-bg .vs-button {
  width: 100% !important;
}
.header-text {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}
</style>

<style lang="scss">
.con-img-upload .con-input-upload {
  width: 100% !important;
  margin: 5px;
  // background: rgba(var(--vs-primary), 0.15) !important;
  box-shadow: 0px 5px 5px rgba(var(--vs-primary), 0.5);
}
.con-img-upload .con-input-upload:hover {
  box-shadow: 0px 10px 5px rgba(var(--vs-primary), 0.5);
}
.con-img-upload .con-input-upload:active {
  box-shadow: 0px 1px 5px rgba(var(--vs-primary), 0.5);
}

.con-img-upload .text-input {
  font-size: 12px;
  margin-top: -24px !important;
}

.con-img-upload .img-upload {
  display: none;
}
.con-img-upload .on-progress-all-upload {
  opacity: 1 !important;
}
.con-img-upload .disabled-upload {
  height: 4px !important;
}
</style>
